@import "statusbar.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    margin-bottom: 45px;
}

.prose h3 {
    @apply text-red;
}

.prose ul > li::before {
    @apply bg-black;
}

nav, .mobile-footer {
    @apply text-red font-bold text-sm;
    margin-left: 300px;
    margin-right: 200px;
}
nav ul, .mobile-footer ul {
    @apply flex justify-between;
}
nav a, .mobile-footer a {
    @apply uppercase;
    color: inherit;
}
nav a::before, .mobile-footer a::before {
    content: '►';
}

header {
    background-image: url("/images/headerback.png");
    background-repeat: repeat-x;
    height: 157px;
}

header .abc {
    @apply flex text-xs;
    margin-bottom: 45px;
}
header .abc ul {
    margin-left: 5px;
}
header .abc li {
    padding: 0 2px;
}
header .abc li a {
    @apply text-red font-bold;
}

footer {
    @apply text-red text-xs mt-2;
}
footer a {
    color: inherit;
}
footer a::before {
    content: '▸';
    padding-right: 5px;
}

.content {
    @apply flex;
    padding-left: 15px;
}

aside {
    width: 268px;
    background-image: url("/images/contentback4.png");
    background-repeat: repeat-y;
    background-position: right;
}
aside .spacer-top {
    height: 17px;
    background-image: url("/images/topcorner.png");
    background-repeat: no-repeat;
    background-position: top right;
}
aside .searchform {
    @apply pt-2;
    padding-left: 20px;
    background-image: url("/images/searchback.png");
    background-repeat: repeat-y;
    min-height: 250px;
}
aside .searchform label {
    @apply text-xs font-bold mt-3 block;
}
aside .searchform input {
    @apply border border-gray-light rounded;
    width: 194px;
    padding-left: 5px;
    padding-right: 5px;
}
aside .searchform select {
    @apply border border-gray-light rounded;
    width: 194px;
}
aside .title {
    @apply font-bold text-sm;
}
aside .spacer-bottom {
    @apply w-full;
    background-image: url("/images/searchbottom.png");
    background-repeat: no-repeat;
    height: 23px;
}

main {
    min-height: 500px;
    background-image: url("/images/contentback2.png");
    background-repeat: repeat;
}

main {
    @apply flex-1 flex flex-col;
}

main .main {
    @apply flex-1 p-4;
}

main .main-border-bottom {
    background-image: url("/images/contentback3.png");
    background-repeat: repeat-x;
    background-position: bottom;
    height: 4px
}

.btn-suchen {
    background-image: url("/images/suchen.png");
    border: medium none;
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
    height: 22px;
    width: 85px;
    margin-top: 5px;
    margin-left: 115px;
}

.content-wrapper {
    @apply border border-red;
    padding: 1px;
}
.content-field {
    @apply px-2;
    max-width: 1042px;
}
.content-header {
    @apply w-full bg-red text-white content-field border border-white;
}

.content-company {
    @apply grid;
    grid-template-columns: 20% auto;
}

.content-field .komma::after {
    content: ',';
}
.content-field .komma:last-child::after {
    content: '';
}

.company-logo {
    @apply float-right py-0 my-0;
    padding-left: 10px;
    padding-bottom: 10px;
}
.company-logo img {
    @apply py-0 my-0;
}

table.businesshours {
    @apply my-0;
    width: auto;
}
table.businesshours tr {
    @apply border-0;
}
table.businesshours td {
    @apply py-0 pr-5;
}

.mobile-footer {
    display: none;
}

.has-errors {
    @apply text-red;
}

.flashmessage {
    @apply bg-red text-white font-bold leading-relaxed px-2 mb-4;
}
.notify_success {
    @apply bg-red;
}
.notify_failure {
    @apply bg-orange;
}

.nav-pagination nav {
    @apply m-0;
}
.nav-pagination nav ul {
    @apply list-none;
    justify-content: inherit;
}
.nav-pagination nav ul > li::before {
    content: '';
    position: relative;
}
.nav-pagination nav a::before {
    content: '';
}

@media only screen and (max-width: 800px) {
    header .abc { display: none }
    aside {
        align-self: end;
        background-image: none;
    }
    aside .spacer-top { display: none }
    main {
        @apply mt-4;
    }
    nav {
        @apply mt-5 mb-0 mx-2;
    }
    .mobile-footer {
        @apply mx-2;
    }
    nav ul, .mobile-footer ul {
        @apply flex-col;
    }
    footer { display: none }
    .content {
        @apply flex flex-col p-0;
    }
    .company-logo {
        @apply float-none pl-0;
    }
    .company-whatsapp {
        float: none !important;
        padding-bottom: 10px !important;
    }
    .content-company {
        grid-template-columns: auto;
    }
    .mobile-footer {
        display: block;
    }

    .search .grid {
        display: block;
    }

    .search .grid-cols-4 {
        @apply border-b border-red
    }
    .search .grid-cols-4:first-child {
        @apply border-0;
    }

    .nav-pagination nav ul {
        @apply flex-row;
    }
}

.company-whatsapp { float: right; padding: 8px 8px 0 0; }
.company-whatsapp img {
    margin: 0;
    max-width: 160px;
}
